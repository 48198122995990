/**
 * Tabs
 *
 * Gestion des tabs
 */
require('bootstrap');

$(document).ready(function() {

  $('#v-pills-tab a').on('click', function (e) {
    e.preventDefault();
    $(this).tab('show');
  });

});
